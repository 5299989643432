import { useState, useCallback } from 'react';
export default function useTable(props) {
    var _a = useState(!!(props === null || props === void 0 ? void 0 : props.defaultDense)), dense = _a[0], setDense = _a[1];
    var _b = useState((props === null || props === void 0 ? void 0 : props.defaultOrderBy) || 'name'), orderBy = _b[0], setOrderBy = _b[1];
    var _c = useState((props === null || props === void 0 ? void 0 : props.defaultOrder) || 'asc'), order = _c[0], setOrder = _c[1];
    var _d = useState((props === null || props === void 0 ? void 0 : props.defaultCurrentPage) || 0), page = _d[0], setPage = _d[1];
    var _e = useState(''), search = _e[0], setSearch = _e[1];
    var _f = useState((props === null || props === void 0 ? void 0 : props.defaultRowsPerPage) || 25), rowsPerPage = _f[0], setRowsPerPage = _f[1];
    var _g = useState((props === null || props === void 0 ? void 0 : props.defaultSelected) || []), selected = _g[0], setSelected = _g[1];
    var handleResetFilter = function () {
        setSearch('');
    };
    var onSort = useCallback(function (id) {
        var isAsc = orderBy === id && order === 'asc';
        if (id !== '') {
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(id);
        }
    }, [order, orderBy]);
    var onSelectRow = useCallback(function (id) {
        var selectedIndex = selected.indexOf(id);
        var newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        }
        else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        }
        else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        }
        else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
    }, [selected]);
    var onSelectAllRows = useCallback(function (checked, newSelecteds) {
        if (checked) {
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }, []);
    var onChangePage = useCallback(function (event, newPage) {
        setPage(newPage);
    }, []);
    var onChangeRowsPerPage = useCallback(function (event) {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    }, []);
    var onChangeDense = useCallback(function (event) {
        setDense(event.target.checked);
    }, []);
    return {
        dense: dense,
        order: order,
        page: page,
        orderBy: orderBy,
        rowsPerPage: rowsPerPage,
        //
        selected: selected,
        onSelectRow: onSelectRow,
        onSelectAllRows: onSelectAllRows,
        //
        onSort: onSort,
        onChangePage: onChangePage,
        onChangeDense: onChangeDense,
        onChangeRowsPerPage: onChangeRowsPerPage,
        //
        setPage: setPage,
        setDense: setDense,
        setOrder: setOrder,
        setOrderBy: setOrderBy,
        setSelected: setSelected,
        setRowsPerPage: setRowsPerPage,
        //
        search: search,
        setSearch: setSearch,
        handleResetFilter: handleResetFilter,
    };
}
